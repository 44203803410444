@import "./resources/assets/scss/_modules/variables";
@import "./node_modules/bootstrap/scss/bootstrap";

.breadcrumb-item {
    + .breadcrumb-item {
        &::before {
            padding-left: $breadcrumb-item-padding;
        }
    }
}

html[dir="rtl"] {
    .breadcrumb-item {
        + .breadcrumb-item {
            padding-right: $breadcrumb-item-padding;

            &::before {
                float: right; // Suppress inline spacings and underlining of the separator
            }
        }
    }

    .modal-header {
        .close {
            padding: $modal-header-padding;
            margin: (-$modal-header-padding-y) auto (-$modal-header-padding-y) (-$modal-header-padding-x);
        }
    }

    .select2-container--default .select2-selection--single .select2-selection__arrow {
        right: auto;
        left: 0.5rem;
    }

    .modal-footer {
        // Easily place margin between footer elements
        > :not(:first-child) {
            margin-right: .25rem;
        }

        > :not(:last-child) {
            margin-left: .25rem;
        }
    }

    .custom-control-label {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently */

        &::before {
            right: -($custom-control-gutter + $custom-control-indicator-size);
            left: 0;
        }

        &::after {
            right: -($custom-control-gutter + $custom-control-indicator-size);
            left: 0;
        }
    }

    .custom-switch {
        .custom-control-label {
            &::before {
                right: -($custom-switch-width + $custom-control-gutter);
                left: 0;
            }

            &::after {
                right: add(-($custom-control-gutter + $custom-control-indicator-size), $custom-control-indicator-border-width * 2);
                left: 0;
            }
        }
    }

    .custom-control {
        padding-left: 0;
        padding-right: $custom-control-gutter + $custom-control-indicator-size;
    }

    .nav {
        padding-right: 0;
    }

    .custom-control-input {
        right: 0;
        left: auto;
    }

    .btn-group {
        // Prevent double borders when buttons are next to each other
        > .btn:not(:first-child),
        > .btn-group:not(:first-child) {
            margin-right: -$btn-border-width;
        }

        // Reset rounded corners
        > .btn:not(:last-child):not(.dropdown-toggle),
        > .btn-group:not(:last-child) > .btn {
            @include border-left-radius(0);
            @include border-right-radius($btn-border-radius);
        }

        > .btn:not(:first-child),
        > .btn-group:not(:first-child) > .btn {
            @include border-right-radius(0);
            @include border-left-radius($btn-border-radius);
        }
    }

    .dropdown-menu {
        text-align: right;
    }

    @include border-radius($input-border-radius);

    .input-group {
        > .form-control,
        > .custom-select {
            &:not(:last-child) {
                @include border-right-radius($input-border-radius);
                @include border-left-radius(0);
            }

            &:not(:first-child) {
                @include border-left-radius($input-border-radius);
                @include border-right-radius(0);
            }
        }

        // Custom file inputs have more complex markup, thus requiring different
        // border-radius overrides.
        > .custom-file {
            &:not(:last-child) .custom-file-label,
            &:not(:last-child) .custom-file-label::after {
                @include border-right-radius($input-border-radius);
                @include border-left-radius(0);
            }

            &:not(:first-child) .custom-file-label {
                @include border-left-radius($input-border-radius);
                @include border-right-radius(0);
            }
        }
    }

    .input-group > .input-group-prepend > .btn,
    .input-group > .input-group-prepend > .input-group-text,
    .input-group > .input-group-append:not(:last-child) > .btn,
    .input-group > .input-group-append:not(:last-child) > .input-group-text,
    .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle),
    .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
        @include border-left-radius(0);
        @include border-right-radius($input-border-radius);
    }

    .input-group > .input-group-append > .btn,
    .input-group > .input-group-append > .input-group-text,
    .input-group > .input-group-prepend:not(:first-child) > .btn,
    .input-group > .input-group-prepend:not(:first-child) > .input-group-text,
    .input-group > .input-group-prepend:first-child > .btn:not(:first-child),
    .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
        @include border-left-radius($input-border-radius);
        @include border-right-radius(0);
    }
}

/* #GLOBAL IMPORTS
========================================================================== */
@import '_imports/_global-import';

/* #WEBFONT ICONS IMPORTS
========================================================================== */
@import '../custom/webfonts/fontawesome-pro-master/scss/fontawesome.scss';
@import '../custom/webfonts/fontawesome-pro-master/scss/light.scss';
@import '../custom/webfonts/nextgen-fonts-master/scss/ng-icons.scss';
